import React from 'react';
import Card from '../Components/Cards';
import VideoBg from '../Images/viedobg.mp4';

const Biznes = () => {
  return (
    <>
    <div className='video'>
<video src={VideoBg} autoPlay loop muted/>
    </div>
<div>
  <Card/>
</div>
</>
  );
};

export default Biznes;