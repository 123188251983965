import React from 'react';
import '../Style/lajme.scss';
import CardsAPI from  '../Components/CardsAPI'
import VideoBg1 from '../Images/videobg1.mp4';

const Lajme = () => {
  return (
    <>
    <div className='fontt'>
      <h1>Lajme</h1>
    </div>
    <div className='video'>
<video src={VideoBg1} autoPlay loop muted/>
    </div>
    <div>
      <CardsAPI/>
    </div>
    </>
  );
};

export default Lajme;