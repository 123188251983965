import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import  './readmore.scss';

const Readmore1 = () => {
    return (
        <Container>

       <div className='divipar'>
      <Card >
        <Card.Img variant="top" src="https://klankosova.tv/wp-content/uploads/2022/09/Capture-47.jpg" />
        <Card.Body>
          <Card.Title className='title'>ShBA paralajmëron për një uragan që do të kalojë pranë Shqipërisë</Card.Title>
          <Card.Text>
          Një uragan i kategorisë 1 do të godasë kontinentin evropian rreth datës 13 shtator, i cili pritet të kalojë pranë Shqipërisë dhe Italisë. Ky është paralajmërimi që vjen nga Shtetet e Bashkuara.

Sipas një publikimi zyrtar të Qendrës Meteorologjike Amerikane, NOAA, uragani është i kategorisë së parë dhe do të vijë nga Atlantiku duke hyrë në Mesdhe, pranë Shqipërisë dhe Italisë.

Uragani i kategorisë së parë quhet stuhi ku era arrin deri në 153 kilometra në orë, raporton Newsdelivers, përcjell Klankosova.tv.

Pasojat maksimale të zonave të prekura nga kjo kategori janë dëmtimet e kufizuara të ndërtesave dhe pemëve, ndërsa në zonat bregdetare parashikohen përmbytje me ngritjen e ujit deri në 50 centimetra mbi nivelin e detit.
          </Card.Text>
          <div className='button'>
          <Button href="/lajme"variant="danger">Kthehuni Mrapa</Button>
          </div>
        </Card.Body>
      </Card>
      </div>
      </Container>
    );
  }

export default Readmore1;