import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Components-Styles/Accordion.scss';


export default function SimpleAccordion() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Sa kanale përfshihen në platformën Bledi ISP?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className='texttt'>
          Në platformën Bledi ISP, përfshihen mbi 250 kanale që janë të ndara në paketa.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            Cilat kanale televizive transmetohen në kualitetin HD?</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className='texttt'>
          Të gjitha kanalet nacionale dhe lokale të Kosovës që kanë sinjal HD transmetohen me sinjal HD.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Sa është shpejtësia e Internetit?</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className='texttt'>
        Varësisht nga pakoja, shpejtësia e internetit fillon nga 100 Mbps e më shumë...
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Ku mund të kontaktoj në rast të prishjes?</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Typography className='texttt'>
        Ju mund të kontaktoni në këto numra tanë të telefonit: <br></br> +383 (44) (49) 143 091 <br></br>  +383 (44) 728 764<br></br>  +383 (44) 415 601<br></br> e-mail: ispbledi@gmail.com
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion>
    </div>
  );
}
