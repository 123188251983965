import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import  './readmore.scss';

const Readmore6 = () => {
    return (
        <Container>

       <div className='divipar'>
      <Card >
        <Card.Img variant="top" src="https://klankosova.tv/wp-content/uploads/2020/12/d52e04ef-e087-4d09-965c-88def768ccce-AP_AP_Explains_Google_Search_Results-scaled.jpg" />
        <Card.Body>
          <Card.Title className='title'>“Search” i Google ra nga sistemi, kompania kërkon falje</Card.Title>
          <Card.Text>
          Gjiganti i teknologjisë “Google” ka kërkuar falje pasi përditësimi i softuerit të ri ka shkaktuar daljen nga kontrolli të butonit “search” të martën.

Një zëdhënës i kompanisë tha se ekipi kishte punuar shpejtë për të adresuar defektin dhe shërbimet për një kohë të shkurtët u kthyen në funksion normalisht.

“Ne jemi të vetëdijshëm për një problem të përditësimit të softuerit që ndodhi vonë këtë pasdite në kohën e Paqësorit dhe ndikoi shkurtimisht në disponueshmërinë e kërkimit në Google dhe Maps”, thanë ata, shkruan TheGuardian, transmeton Klankosova.tv

Problemi u raportuar nga disa platforma teknologjike si “Downdetector” e  kompania e inteligjencës së rrjetit “ThosuandEyes Inc”.
          </Card.Text>
          <div className='button'>
          <Button href="/lajme"variant="danger">Kthehuni Mrapa</Button>
          </div>
        </Card.Body>
      </Card>
      </div>
      </Container>
    );
  }

export default Readmore6;