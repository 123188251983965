import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import './Components-Styles/Tabs.scss';

export default function App() {
    return (
        <div className='firstdiv'>
            <div calssName="acordion">
                <h4>Rreth Nesh</h4>
                <div className='firstdivv'>
                <Tabs defaultActiveKey="foruth">
                    <Tab eventKey="first" title="Kush jemi ne ?!">
                    <br></br>
                    
                        Në vitin 2007 Bledi ISP u ri-brendua në tërësi dhe filloj zgjerimin e rrjetit broadband në lokacionet e disa fshatrave të komunes së Malishevës duke përdorur teknologjinë e fundit për ofrim të shërbimeve brezgjerë tek klientë rezidencial dhe biznes.<br></br><br></br> 
                    </Tab>
                    <Tab eventKey="second" title="Pse Bledi-ISP?">
                    <br></br>
                    Bledi-ISP ka sjellur internetin me shpejtësi pa limit, mbi 200 kanale, si dhe pako sportive, të cilat shtrihen në disa fshatra të komunes së Malishevës. Komplet rrjeta është pasive dhe nuk ndikohet nga intereferimi i pajisjeve tjera.</Tab>
                    <Tab eventKey="third" title="Çka e dallon Bledi-ISP nga konkurrenca?">
                    <br></br>
                        Bledi-ISP vjen si kompania e re në tregun e Kosovës me kabllimin me fije optike.
                        Karakteristikë tjetër që e dallon Bledin dhe e bën konkurrent serioz në tregun e internetit është pikërisht rrjeta më e avancuar e mundshme dhe e vetmja deri më tani me kualitet dhe çmim të arsyeshëm.
                    </Tab>
                    <Tab eventKey="foruth" title="Rreth Nesh!!">
                    <br></br>
                        Bledi-ISP është kompani që ofron Internet dhe Televizion Kabllor Digjital, dizajnime të reklamave të ndryshme, zhvillim të aplikacioneve, mirëmbajtje të rrjeteve sociale, zhvillim të ueb faqeve, shitje dhe servisim të aparaturave elektronike.<br></br><br></br>
                        Kompania jonë ofron shërbimet tona në disa fshatra të komunes së Malishevës.<br></br><br></br>
                        Kemi 1 dyqane zyrtare të shitjes me përkrahje teknike dhe qendër kujdesi për konsumatorët.<br></br><br></br>
                        Ky dyqan punonë 24 orë në ditë, 7 ditë në javë, gjatë tërë vitit dhe një rrjet të gjerë distributorësh të autorizuar.<br></br><br></br>
                        Cilësia e lartë dhe serioziteti në punë për një periudhë të gjatë kohore ka ndikuar që së bashku me klientë të rritemi edhe më shumë.<br></br>
                        </Tab>
                </Tabs>
                </div>
            </div>
        </div>
    );
}
