import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Components-Styles/cardsapi.scss';

const CardsAPI = () => {
    const [news, SetNews] = useState();
    const getAPIData = async () => {
        const response = await fetch("https://retoolapi.dev/el3gfI/lajme").then((response) => response.json());

        SetNews(response);

    }
    useEffect(() => {
        getAPIData();
    }, [])
    return (
        <div>
            <Container>
                <Row>
                    {news &&
                        news.map((news) => (
                            <Col md={4}>
                                <Card className="cards">
                                    <Card.Body>
                                        <div className="foto">
                                            <Card.Img className='pako-image' variant="top" src={news.isUser} />
                                        </div>
                                        <Card.Text className='pako-date'>{news.image}
                                        </Card.Text>
                                        <Card.Text className='pako-title'>{news.date}
                                        </Card.Text>
                                        <Card.Text className='pako-description'>{news.title}
                                        </Card.Text>
                                        <div className="buttonn">
                                            <Button href={news.button} variant="primary">Lexoni më shumë</Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))
                    }
                </Row>
            </Container>

        </div>
    )
}
export default CardsAPI