
import Card from 'react-bootstrap/Card';
import './Components-Styles/Cards.scss'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Pako1 from '../Images/home_internet_pic1.png';
import Pako2 from '../Images/home_internet_pic3.png';
import Pako3 from '../Images/home_internet_pic4.png';




function BasicExample() {
  const Data = [
    {
      id: 1,
      title: 'Internet ',
      description: 'Internet të shpejtë, të sigurtë, pa kufizime deri në 150 MBPS Fiber Optic',
      image: Pako1
    },
    {
      id: 2,
      title: 'Pako TV',
      description: 'Përjeto pasionin unik me platformën tonë. Me më shumë se 200 kanale HD',
      image: Pako2
    },
    {
      id: 3,
      title: 'Kujdesi ndaj klientit',
      description: 'Klientët tanë trajtohen me kujdes të shtuar dhe me profesionalizëm!',
      image: Pako3
    },

  ]
  return (
    <>
      <div className='slide'>
        <Container>
          <Row>
            {Data.map((paketa) => (
              <Col md={4}>

                <Card className="cardss">
                  <Card.Img className='pako-imagee' variant="top" src={paketa.image} />
                  <Card.Body>
                    <Card.Title className='pako-titlee'>{paketa.title} </Card.Title>
                    <Card.Text className='pako-descriptionn'>{paketa.description}
                    </Card.Text>
                  </Card.Body>
                </Card>

              </Col>


            ))}
          </Row>
        </Container>
      </div>
    </>
  );

}

export default BasicExample;