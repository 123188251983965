import React from 'react';
import '../Style/sherbimet.scss';
import foto1 from '../Images/fiberoptic.png';
import foto2 from '../Images/photoimage.png';
import foto3 from '../Images/pakobazikeplus.png';
import foto4 from '../Images/pakostandartplus.png';
import foto5 from '../Images/pakopremiumplus.png';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/Button';


const Sherbimet = () => {
  return (
    <div className='sherbimet'>
      <div className='sherbimet_foto'>
        <img src={foto1}></img>
      </div>
      <Container>
      <div className='sherbimet_font'>
        <h1>Shërbimet më të mira në treg</h1>
      </div>
      <div className='sherbimett'>
        <div className='sherbimet_foto1'>
          <img src={foto2}></img>
        </div>
        <div className='sherbimet_fontt'>
          <h3>Bledi ISP sjell internetin me shpejtësi pa limit, mbi 200 kanale, si dhe pako sportive, të cilat shtrihen në të gjithë Kosovën.</h3>
        </div>
      </div>
     <div className='kushtet'>
      <Button  href='/kushteteabonimitt' variant="primary" size="lg">
      KUSHTET E ABONIMIT
        </Button>{' '}
        </div>
      <div className='sherbimet_pako'>
        <div className='sherbime_pakot'>
          <img src={foto3}></img>
          <Button  className='sherbime_button' variant="outline-success">Apliko tani!</Button>{' '}
        </div>
        <div className='sherbime_pakot'>
          <img src={foto4}></img>
          <Button  className='sherbime_button' variant="outline-danger">Apliko tani!</Button>{' '}
        </div>
        <div className='sherbime_pakot'>
          <img src={foto5}></img>
          <Button  className='sherbime_button' variant="outline-warning" >Apliko tani!</Button>{' '}
        </div>
        </div>
      </Container>
    </div>
  );
};

export default Sherbimet;