import React from 'react';
import '../Style/rrethnesh.scss';
import Tabs from '../Components/Tabs';
import foto6 from '../Images/foto6.png';
import foto71 from '../Images/foto71.png';



const Rrethnesh = () => {
  return (
    <>
           <div className='Contanier'>
        <div className='fotooo'>
          <img src={foto6}></img>
        </div>

        <div className='textt'>
          <h1>Kush jemi ne ?</h1>
        </div>
        <div className='firstbody'>
          <div className='body'>
            <div className="text1">
      
            <div className='fotoooo'>
          <img src={foto71}></img>
        </div>
         

            </div>
          </div>
    
        <div className='tabss'>
          <Tabs />
        </div>
        </div>

      </div>
  
    </>

  );
};

export default Rrethnesh;