import React, { useState } from 'react';
import './FormStyle/login.scss';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import background from '../Images/background.jpeg'

function Login() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
      
  <div  className=' background' style={{ backgroundImage: `url(${background})`}}>

<div className='containeeer'>
      <div className="Firstttt">
      <h3 className="texttt" style ={{color: "white" }} >Kyquni!</h3>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-4">

            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
            <Form.Label style ={{color: "white"}}>Username</Form.Label>

              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  aria-describedby="inputGroupPrepend"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Ju lutem shenoni Username.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-4">
          <Form.Group as={Col} md="12" controlId="formBasicPassword">
        <Form.Label style ={{color: "white"}} >Password</Form.Label>
        <Form.Control
                  type="password"
                  placeholder="Password"
                  aria-describedby="inputGroupPrepend"
                  required
                />
        <Form.Control.Feedback type="invalid">
                  Ju lutem shenoni Passwordin.
                </Form.Control.Feedback>
      </Form.Group>
      </Row>


          <Button type="submit">Kyquni</Button>
        </Form>

      </div>
      </div>
      </div>

   

  );
}
export default Login;
