import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import  './readmore.scss';

const Readmore2 = () => {
    return (
        <Container>

       <div className='divipar'>
      <Card >
        <Card.Img variant="top" src="https://klankosova.tv/wp-content/uploads/2022/09/6145c05befc3b9d8fe3c5d8bb7062d46o2.jpg" />
        <Card.Body>
          <Card.Title className='title'>Vazhdon “Lewangolski”, Barcelona e mposht Sevillën</Card.Title>
          <Card.Text>Barcelona e ka mposhtur me rezultat 3-0 Sevillën në Sanchez Pizjuan.

Goli i parë për katalunasit erdhi pas 21 minutash lojë, ku Raphina u gjend në pozitën e duhur pas një kthimi në zonën e gjashtë metërshit.

15 minuta më vonë ishte Lewandowski ai i cili çoi rezultatin në 0-2, i cili ishte edhe rezultati i pjesës së parë.

Pesë minuta pas nisjes së pjesës së dytë ishte Eric Garcia ai i cili shënoi golin e fundit për Barcelonën.

Me këtë fitore, Barcelona është në vendin e dytë me 10 pikë, dy më pak se lideri, Real Madrid. Ndërsa, Lewandowski është golashënuesi i parë në ligë me pesë gola, dy më shumë se rivali i tij kryesor, Benzema.  </Card.Text>
          <div className='button'>
          <Button href="/lajme"variant="danger">Kthehuni Mrapa</Button>
          </div>
        </Card.Body>
      </Card>
      </div>
      </Container>
    );
  }

export default Readmore2;