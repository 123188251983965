import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import  './readmore.scss';

const Readmore5 = () => {
    return (
        <Container>

       <div className='divipar'>
      <Card >
        <Card.Img variant="top" src="https://klankosova.tv/wp-content/uploads/2022/08/Galaxy-Z-Flip4_2.jpg" />
        <Card.Body>
          <Card.Title className='title'>Galaxy Z Fold4 dhe Z Flip4, smartphone-t më të rinj nga Samsung</Card.Title>
          <Card.Text>
          Samsung krijoi pajisjet e para të palosshme dhe mbetet lider në treg, nga softueri te hardueri, dizajni e deri te shitjet. Seritë Galaxy Z janë telefonat inteligjentë më fleksibël në treg sot. Me dizajn që ndryshon formën, ekrane që të përfshijnë në zemër të përmbajtjes, veçori që lehtësojnë kryerjen e disa detyrave njëkohësisht, foto dhe video pa duar, seria Z ripërcakton telefonin inteligjent, duke ju dhënë përfitime të reja falë formës unike.
          Galaxy Z Fold 4 vjen me një ekran 6,2 inç (i mbyllur) dhe ekranin kryesor 7,6 inç (i hapur) Infinity Flex me një ekran më të ndritshëm, një rifreskim adaptiv 120 Hz dhe një kamerë më pak të dukshme nën ekran (UDC) që paraqet një rregullim të ri. Një nga komponetët e ri është dhe platforma e fuqishme celulare Qualcomm Snapdragon 8+ Gen 1. Për më tepër, është pajisja e parë që vjen me Android 12L, një version i veçantë i Android i krijuar nga Google për eksperienca me ekran të madh, përfshirë pajisjet e palosshme.
          </Card.Text>
          <div className='button'>
          <Button href="/lajme"variant="danger">Kthehuni Mrapa</Button>
          </div>
        </Card.Body>
      </Card>
      </div>
      </Container>
    );
  }

export default Readmore5;