import React from 'react';
import { CDBFooter, CDBBtn, CDBIcon, CDBBox } from 'cdbreact';
import './Components-Styles/footerstyles.scss';
import Logo from '../Images/Logo.png';

export const Footer = () => {
  return (
    <div className='bledi'>
      <CDBFooter className="shadow">

        <CDBBox display="flex" flex="column" className=" mx-auto py-5" style={{ width: '80%' }}>
          <CDBBox display="flex" justifyContent="between" className="flex-wrap" >
            <CDBBox >
              <a href="/" className="logofooter">
                <img src={Logo} alt='logo'/>
              </a>
            </CDBBox>
            <CDBBox display="flex" style={{ width: '50%', color: "white" }} justifyContent="between">
              <CDBBox>
                <p className="parageafi" style={{ fontSize: '25px', fontWeight: '600' }}>
                  Ndihmë
                </p>
                <CDBBox className="bb" flex="column" display="flex" style={{ cursor: 'pointer', padding: '0', color: 'white' }}>
                  <br></br>
                  <h6>Pyetjet E Shpeshta</h6>
                  <h6>Politika E Privatësisë</h6>
                  <h6>Kushtet e  Përdorimi</h6>
                  <h6>Kontaktoni</h6>

                </CDBBox>
              </CDBBox>
              <CDBBox>
                <p className="parageafii" style={{ fontSize: '25px', fontWeight: '600', color: "white" }}>Kontakti                </p>
                <CDBBox className="bbb" display="flex" flex="column" style={{ cursor: 'pointer', padding: '0', color: 'white' }}>
                  <br></br>
                  <h6>Rilindja Kombëtare,
                    Prishtin, Kosovo</h6>
                  <h6>Email: Info@isp-bledi.net</h6>
                  <h6>Tel: +383 (0) 44 143 091</h6>
                  <h6>Tel: +383 (0) 44 415 601</h6>
                </CDBBox>
              </CDBBox>
            </CDBBox>
          </CDBBox>
          <CDBBox display="flex" className="mt-4" justifyContent="between" color="white" >
            <small className="ml-2">&copy; Bledi ISP, 2022. All rights reserved.</small>
            <CDBBox display="flex">
              <CDBBtn href="https://www.facebook.com/ispbledi"  flat color="dark" className="p-2">
                <CDBIcon  fab icon="facebook-f" />
              </CDBBtn>
              <CDBBtn href="https://www.facebook.com/ispbledi" flat color="dark" className="mx-3 p-2">
                <CDBIcon fab icon="twitter" />
              </CDBBtn>
              <CDBBtn href="https://www.instagram.com/bledi.isp/" flat color="dark" className="p-2">
                <CDBIcon fab icon="instagram" />
              </CDBBtn>
            </CDBBox>
          </CDBBox>
        </CDBBox>
      </CDBFooter>
    </div>
  );
};