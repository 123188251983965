import React from 'react';
import '../Style/ballina.scss'
import Carousel from '../Components/Carusel';
import Card from '../Components/Cards';
import foto5 from '../Images/foto5.png';
import Accordion from '../Components/Accordion'


const Ballina = () => {
  return (
    <>
      <div>
        <Carousel />
      </div>

      <div className='font'>
        <h2>Ofertat më të mira!</h2>
      </div>
      <div className='font1'>
        <h5>...vijmë me shpejtësinë e dritës</h5>
      </div>
      <div >
        <Card />
      </div>
      <div className='fontpakot'>
          <h3> Pyetjet e shpeshta</h3>
        </div>
      <div className='fundi'>

        <div className='foto5'>
          <img src={foto5}></img>

        </div>
        <div className='Accordion'>

          <Accordion />

        </div>
      </div>
    </>
  );
};

export default Ballina;