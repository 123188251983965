import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import  './readmore.scss';

const Readmore3 = () => {
    return (
        <Container>

       <div className='divipar'>
      <Card >
        <Card.Img variant="top" src="https://klankosova.tv/wp-content/uploads/2022/09/302618639_1571930506572341_288153400843225456_n-1.jpg" />
        <Card.Body>
          <Card.Title className='title'>Pse kompanitë, madje edhe Joe Biden, po bëjnë postime në rrjete sociale me një fjalë?</Card.Title>
          <Card.Text>
          Por, ky postim i Klan Kosovës ishte krejt i qëllimshëm duke iu bashkuar kështu trendit global të postimeve njëfjalëshe në rrjetet sociale.

Pjesë të këtij trendi u bënë shumë fytyra publike, e madje edhe lideri i shtetit më të fuqishëm në botë – Joe Biden – i cili cicëroi fjalën “demokraci”.<br></br>

Por, si u bë trend postimi i vetëm një fjale në Twitter?<br></br>

Gjithçka nisi kur kompania amerikane e trenave “Amtrak” postoi gabimisht në Twitter fjalën “trenat” të enjten në mbrëmje.

Ky postim mori më shumë se 153 mijë pëlqime dhe u shpërnda 23 mijë herë deri në mesditën e ditës së nesërme, pasi përdoruesit e këtij rrjeti social e kishin spikatur këtë lëshim.
          </Card.Text>
          <div className='button'>
          <Button href="/lajme"variant="danger">Kthehuni Mrapa</Button>
          </div>
        </Card.Body>
      </Card>
      </div>
      </Container>
    );
  }

export default Readmore3;