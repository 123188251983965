import React, { useState } from 'react';
import './FormStyle/register.scss';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


function Register() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    

      <div className="Firsttt">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="aaaaa">
      <h3>Regjistrohuni !</h3>
      </div>
          <Row className="mb-3">
            <Form.Group as={Col} md="4" controlId="validationCustom01">
              <Form.Label style ={{color: "white"}}>Emri</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Sheno Emrin"

              />
              <Form.Control.Feedback>Shum mir!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustom02">
              <Form.Label style ={{color: "white"}}>Mbiemri</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Sheno Mbiemrin"
              />
              <Form.Control.Feedback>Shum mir!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="validationCustomUsername">
              <Form.Label style ={{color: "white"}}>Username</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  aria-describedby="inputGroupPrepend"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Ju lutem shenoni Username.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="validationCustom03">
              <Form.Label style ={{color: "white"}}>Qyteti</Form.Label>
              <Form.Control type="text" placeholder="Sheno Qytetin" required />
              <Form.Control.Feedback type="invalid">
                Ju lutemi jepni një qytet .
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom04">
              <Form.Label style ={{color: "white"}}>Shteti</Form.Label>
              <Form.Control type="text" placeholder="Sheno shtetin" required />
              <Form.Control.Feedback type="invalid">
                Ju lutemi jepni një Shtet.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="validationCustom05">
              <Form.Label style ={{color: "white"}}>Zip</Form.Label>
              <Form.Control type="number" placeholder="Zip" required />
              <Form.Control.Feedback type="invalid">
                Ju lutemi jepni një Zip code te vlefshem.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3">
            <Form.Check style ={{color: "white"}}
              required
              label="Pajtohuni me termat dhe kushtet?"
              feedback="Duhet të bini dakord përpara se ti dorëzoni."
              feedbackType="invalid"
            />
          </Form.Group>
          <Button type="submit">Regjistrohuni</Button>
        </Form>

      </div>




  );
}
export default Register;
