import Carousel from 'react-bootstrap/Carousel';
import foto2 from '../Images/foto10.jpeg';
import foto3 from '../Images/foto9.png';
import foto200 from '../Images/Paketatt.png';



function IndividualIntervalsExample() {
    return (

<Carousel style={{ background: 'black' }}>
      <Carousel.Item interval={9000} >
        <img
          className="d-block w-100"
          src={foto200}
          alt="First slide"
        />
        <Carousel.Caption>

        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item interval={9000}>
        <img
          className="d-block w-100"
          src={foto2}
          alt="Third slide"
        />
      </Carousel.Item>
    </Carousel>

        );
    }
    
    export default IndividualIntervalsExample;