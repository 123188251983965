import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import  './readmore.scss';

const Readmore4 = () => {
    return (
        <Container>

       <div className='divipar'>
      <Card >
        <Card.Img variant="top" src="https://klankosova.tv/wp-content/uploads/2022/08/GetFile.jpg" />
        <Card.Body>
          <Card.Title className='title'>Facebook akuzohet për shkelje të privatësisë, mund të gjobitet me mbi 37 milionë euro</Card.Title>
          <Card.Text>
          Kompania Facebook është duke u akuzuar për shkelje të privatësisë së përdoruesve.

Në këtë akuzë thuhet se Facebook ka gjurmuar vend-ndodhjen e tyre, shkruan dailymail.co.uk, transmeton klankosova.tv.

Një padi e propozuar u depozitua të hënën në gjykatën federale të San Franciskos dhe kërkon miratimin e një gjyqtari.

Gjoba e propozuar në këtë padi është 37.5 milionë euro.

Në padi pretendohet se Facebook kishte shkelur ligjin e Kalifornisë dhe politikën e privatësisë duke mbledhur të dhëna nga përdoruesit që çaktivizuan shërbimet e vendndodhjes në pajisjet e tyre celulare.

Përdoruesit thanë se edhe pse nuk donin të ndanin vendndodhjet e tyre me Facebook, kompania megjithatë nxori lokacionin nga adresat e tyre IP (protokolli në internet) dhe e përdori atë informacion për t’u dërguar atyre reklama të synuara.
          </Card.Text>
          <div className='button'>
          <Button href="/lajme"variant="danger">Kthehuni Mrapa</Button>
          </div>
        </Card.Body>
      </Card>
      </div>
      </Container>
    );
  }

export default Readmore4;