import '../Style/kontakti.scss';
import Foto34 from '../Images/foto34.png'
import Kontaktoni from '../Components/kontaktoni.js'
import foto from '../Images/foto392.png'


const Kontakt = () => {
  return (
    <>
      <div className='fotooo'>
        <img src={foto}></img>
      </div>
      <div className="homee">
        <div className="textti">
          <h3>Kur arsyeja që buzëqesh  është rrjeti shumë i shpejtë!!</h3>
        </div>
        <div className='areaa'>
          
            <div className="img">
              <img src={Foto34}></img>
            </div>

          <div>
            <Kontaktoni />
          </div>
        </div>
      </div>
    </>



  )
}

export default Kontakt;