import React, { useState } from 'react';
import './Components-Styles/kontaktoni.scss';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';


function Kontaktoni() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  return (
    <>
      <div className="textareaa">
    
      </div>
      <div className="Firsttttt">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <h3 className='texttttt'>Na Kontaktoni!</h3>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label style ={{color: "white"}}>Emri</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Sheno Emrin"
              />
              <Form.Control.Feedback type="invalid">
                Ju lutemi jepni një Numer Telefoni.
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom03">
              <Form.Label style ={{color: "white"}}>E-mail</Form.Label>
              <Form.Control type="text" placeholder="Sheno E-mail" required />
              <Form.Control.Feedback type="invalid">
                Ju lutemi shenoni Emailin .
              </Form.Control.Feedback>
            </Form.Group>
            </Row>
            <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom04">
              <Form.Label style ={{color: "white"}}>Numrin e Telefonit</Form.Label>
              <Form.Control type="number" placeholder="Numrin e Telfonit" required />
              <Form.Control.Feedback type="invalid">
                Ju lutemi jepni një Numer Telefoni.
              </Form.Control.Feedback>
            </Form.Group>
            </Row>  
              <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom05">
              <Form.Label style ={{color: "white"}}>Subjekti</Form.Label>
              <Form.Control type="text" placeholder="Sheno Subjektin" required />
              <Form.Control.Feedback type="invalid">
                Ju lutemi shenoni Subjektin Tuaj!
              </Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style ={{color: "white"}}>Mesazhi Juaj</Form.Label>
        <Form.Control as="textarea" rows={3} />
      </Form.Group>
          
          <Button type="submit">Dergoni</Button>
        </Form>

      </div>


    </>

  );
}
export default Kontaktoni;
